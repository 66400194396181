<template>
  <div class="app-container">
    <CrudTable entity="VehicleType" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: ['name', { field: 'travelMode', label: 'vehicleType.travelMode' }]
    };
  }
};
</script>

<style scoped></style>
